import styles from './index.module.scss';
import GradientBG from '../layout/gradientBackground';
import Link from "next/link";

export default function NotFound() {
    return (
        <GradientBG>
            <div className={styles.notfound}>
                <div className={styles.inner}>
                    <h1>you&rsquo;ve reached the end of the internet.</h1>
                    <p>The page you&rsquo;re looking for doesn&rsquo;t exist but don&rsquo;t worry, we&rsquo;ll get you back on track.</p>
                    <p><Link href="/"><a>Return Home</a></Link> to find all the loop.tv goodness.</p>
                </div>
            </div>
        </GradientBG>
    )
}